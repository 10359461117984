<template>
  <div style="height:100vh;">
    <van-sticky>
      <div class="header">
        <van-row gutter="10">
          <van-col @click.native="onClickBack">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
              style="height: 25px"
            />
            <span style="font-size: 25px; color: white;font-weight:bold">事件</span>
          </van-col>
        </van-row>
      </div>
    </van-sticky>
    <div style="height:95vh;overflow-y:scroll;margin-top:.2rem">
    <van-pull-refresh v-model="isLoading" @refresh="refreshEvents" style="padding-bottom:4rem">
      <van-list v-model="listLoading" :finished="listFinished" 
        :finished-text="events.length > 0?'没有更多了': ''" 
        @load="loadReportPage" style="padding-bottom:4rem">
        <div style="padding:0 .2rem .2rem .2rem;">
          <card
            style="margin-top: 10px;"
            v-for="(item, index) in events"
            :key="index"
          >
            <template #content>
              <tourInfoTop :tour="item.travel_list" :titleClickable="false"></tourInfoTop>
              <van-divider dashed/>
              <van-row class="row" @click.native="toEventInfo(item.id)">
                <van-col span="12">
                  <span style="font-weight:600"><svg-icon
               style="margin-right:5px;"
               v-if="item.report_type_text=='疫情事件'"
            :data_iconName="'yiqing'"
            :className="''"
          />{{item.report_type_text}}</span>
                </van-col>
                <van-col span="12" style="text-align:right">
                  <span style="color:grey">{{item.created_at}}</span>
                </van-col>
              </van-row>
              <van-row class="row" style="margin-top:10px" @click.native="toEventInfo(item.id)">
                <van-col span="18">
                  <span style="color:grey">{{item.report_content}}</span>
                </van-col>
                <van-col span="6" style="text-align:right">
                  <van-tag color="#13C2C225" text-color="#13C2C2" round
                    v-if="item.is_approved && item.approve_status == 1">{{item.approve_status_text}}</van-tag>
                  <van-tag color="#4168EE12" text-color="#4168EE" round
                    v-if="item.is_approved && item.approve_status == 2">{{item.approve_status_text}}</van-tag>
                  <van-tag color="#6f6f6f23" text-color="#606060" round
                    v-if="!item.is_approved">待审批</van-tag>
                </van-col>
              </van-row>
            </template>
          </card>
          <van-empty v-if="events.length === 0" description="没有事件通知" style="margin-top:.2rem"/>
        </div>
      </van-list>
    </van-pull-refresh>
    </div>
    <div class="action_bg" v-if="onGoingTours.length > 0">
      <van-button
        type="info"
        class="action_btn"
        color="#395EE8"
        @click="toEventAdd()"
        >上报当前行程情况</van-button
      >
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import card from "@/views/components/Card";
import tourInfoTop from "@/views/components/TourInfoTop"
export default {
  name: "eventMain",
  components: {card, tourInfoTop},
  data: () => ({
    isLoading: false,
    listLoading: false,
    paging: {
      total_count: 0,
      total_pages: 0,
      current_page: 1,
      next_page: null
    }
  }),
  computed: {
    ...mapGetters('events', {
      events: 'getAllEvents'
    }),
    ...mapGetters('travel_list', {
      onGoingTours: 'onGoingTours'
    }),
    listFinished() {
      return this.paging.next_page == null
    },
  },
  created() {
    this.refreshEvents()
    this.$store.dispatch('travel_list/getAllTravelLists', 1)
    this.$store.dispatch("wode/getPersonInfo")
  },
  methods: {
    toEventInfo(id) {
      this.$store.dispatch('events/getCurrentEvent', id)
      this.$router.push(`/events/info/${id}`)
    },
    toEventAdd() {
      this.$router.push('/events/report')
    },
    onClickBack() {
      this.$router.push('/home')
    },
    refreshEvents() {
      const _self = this
      this.$store.dispatch('events/getAllEvents').then((res) => {
        if(res.status === 200) {
          _self.isLoading = false
          _self.listLoading = false
          _self.fillPaging(res)
        }
      })
    },
    fillPaging(res) {
      this.paging.total_count = res.total_count
      this.paging.total_pages = res.total_pages
      this.paging.current_page = res.current_page
      this.paging.next_page = res.next_page
    },
    loadReportPage() {
      const _self = this
      if(this.paging.next_page != null) {
        this.$store.dispatch("events/getAllEvents", this.currentReportType, 
          this.paging.next_page).then((res) => {
            if(res.status === 200) {
              _self.listLoading = false
              _self.fillPaging(res)
              _self.$store.dispatch('events/syncEvents', res.data)
            }
          })
      }
    },
  }
};
</script>

<style scoped>
.row {
  font-size: 14px;
}
.action_btn {
  position: fixed;
  bottom: 10px;
  border-radius: 25px;
  width: 95%;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
}
</style>